.terms-content {
  padding: 40px;
}

.terms-content p {
  padding-bottom: 10px !important;
}

.terms-content h3 {
  padding-bottom: 15px !important;
}

.terms-content h2 {
  padding: 15px 0 !important;
}

.terms-heading {
  font-size: 30px;
}

@media (max-width: 768px) {
  .terms-main {
    text-align: center;
  }
  .terms-content {
    padding: 20px;
  }
  .terms-heading {
    font-size: 30px;
  }
}
