/* index.css */

/* Define your color variables */
:root {
  --primary-color: #239f40; /* Define your primary color */
  --secondary-color: #6c757d; /* Define your secondary color */
  --accent-color: #ffc107; /* Define your accent color */
  --background-color: #f8f9f8; /* Define your background color */
  --text-color: #343a40; /* Define your text color */
}

/* :root * {
  text-align: right !important;
}

#dashboard-sidebar a, #dashboard-sidebar div {
  flex-direction: row-reverse !important;
} */

/* #webpack-dev-server-client-overlay {
  display: none;
} */
/* Apply global styles */
html{
  height: 100%;
  overflow-y: auto;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
}

.markdown h1,
.markdown p,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  margin: 10px 0;
}

button {
  font-family: "DM Sans", sans-serif !important;
}

/* Set common border-radius for all buttons */
.MuiButton-root {
  border-radius: 50px;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;
}

.Toastify__toast-container {
  z-index: 999999 !important;
}
.loding-display {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  z-index: 9999999;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.loding-display img {
  width: 100px;
  margin: 0 auto;
  width: 100px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
}
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
  display: none !important;
} */

.MuiSvgIcon-root.MuiSelect-icon {
  display: none !important;
}

.react-tel-input .country-list .country {
  padding: 10px 20px;
  display: flex !important;
}

/* input[type="text"]:focus {
  border-color: #0b3013;
  outline: none;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
  border: 1px solid #0b3013 !important;
  border-radius: 6px;
} */

/* input[type="text"]:focus-visible {
  border: 1px solid #0b3013 !important;
  border-radius: 6px;
} */

input::placeholder {
  font-size: 14px;
}

/* Override MUI Button styles */
.MuiButton-containedPrimary {
  background-color: var(--primary-color) !important;
}

.filterCommonWhite {
  filter: invert(1);
}
/* Typography  */

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
p,
span,
div,
small,
label,
a {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif !important;
}

/* Utility CLass */

/* Text alignment */

.textStart {
  text-align: start;
}

tr:hover {
  background-color: transparent !important ;
}

.avatarImg {
  width: 104px;
  max-width: 100px;
  height: 104px;
  max-height: 104px;
}

.testinomialImg {
  width: 50px;
  max-width: 100px;
  height: 50px;
  max-height: 100px;
}
.MuiTableContainer-root {
  box-shadow: none !important;
}

/* .slick-prev {
  display: none !important;

}
.slick-next {
  display: none !important;

} */
.table-responsive {
  width: 100%;
  margin-bottom: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid #ddd;
}
th,
td {
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .listContent {
    font-size: 15px !important;
  }
  .reviewBannerHead {
    font-size: 15px !important;
  }
  .reviewRateTextResp {
    font-size: 24px !important;
  }
  .heroheading {
    font-size: 45px !important;
  }
  .customClass {
    font-size: 20px !important;
  }
  .counter {
    font-size: 14px !important;
  }
  .resposiveTop {
    padding-top: 6% !important;
  }
  .repoTextTalentSubHead {
    font-size: 1.3rem !important;
  }
  .card2 {
    margin-right: -35% !important;
  }
  .testimonialBodyText {
    font-size: 15px;
  }
  .globalText {
    font-size: 30px !important;
  }
  .talenetP {
    font-size: 16px !important;
    padding-right: 22px !important;
  }
  .followSection {
    margin-right: 4% !important;
  }
  .langText {
    font-size: 16px !important;
  }
  .userNameText {
    font-size: 20px !important;
  }
}

@media (max-width: 900px) {
  .dNoneRespo2 {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .DesignAbs {
    margin-top: -20px;
  }
  .langText {
    font-size: 15px !important;
  }
  .userNameText {
    font-size: 18px !important;
  }
  .followSection {
    margin-right: 5%;
  }
  .globalNetSection {
    padding-top: 10%;
  }
  .reviewBox {
    position: initial !important;
  }
  .dNoneRespo {
    display: none !important;
  }
  .cardMakeReal {
    width: 100% !important;
    max-width: 90% !important;
  }
  .alignItemsCenter {
    align-items: center !important;
  }
  .flexColumnRespo {
    flex-direction: column !important;
  }
  .padTopZero {
    padding-top: 0 !important;
  }
  .counter {
    font-size: 14px;
  }
  .HomeBanner {
    height: 700px !important;
  }
  .resposiveTop {
    padding-top: 5% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .secCounterContainer {
    display: none;
  }
  .projCounterContainer {
    display: none;
  }
  .floatingCounterContainer {
    display: none;
  }
  .heroheading {
    color: #fff;
    font-size: 34px !important;
  }
  .customClass {
    font-size: 17px !important;
  }
  .girlBanner {
    width: 100%;
    max-width: 100%;
    margin-right: -5%;
  }
}

@media (min-width: 320px) and (max-width: 520px) {
  .skillSetChip {
    flex-wrap: wrap !important;
  }
  .avatarImg {
    width: 50px;
    max-width: 100px;
    height: 50px;
    max-height: 104px;
    margin-left: 28px;
  }
  .filterSec {
    margin-top: 5% !important;
  }
  .dNoneRespo {
    display: none !important;
  }
  .heroheading {
    font-size: 24px !important;
  }
  .customClass {
    font-size: 15px !important;
  }
  .counter {
    font-size: 11px;
  }
  .HomeBanner {
    height: 650px !important;
  }
  .HomePartners {
    margin-top: -20% !important;
  }
  .textHeading {
    font-size: 22px !important;
  }
  .talentHeadingMain {
    font-size: 21px !important;
  }
  .flexColumnRespo {
    flex-direction: column !important;
  }
  .catBtnTop {
    margin-top: 6% !important;
  }
  .realP {
    display: block;
    font-size: 15px;
    margin-top: 5%;
  }
  .makeReal {
    padding-left: 0 !important;
  }
  .globalText {
    font-size: 2rem !important;
  }
  .padBotZero {
    padding-bottom: 0 !important;
  }
  .padTopZero {
    padding-top: 0 !important;
  }
  .LoveHeadeText {
    font-size: 22px !important;
  }
  .LoveHeadeTextP {
    font-size: 16px;
  }
  .headSection {
    flex-direction: column !important;
  }
  #footerBG {
    width: max-content !important;
  }
  .ptZero {
    padding-top: 0 !important;
  }
  .pbZero {
    padding-bottom: 0 !important;
  }
  .prZero {
    padding-right: 0 !important;
  }
  .plZero {
    padding-left: 0 !important;
  }
  #signup-modal-description {
    padding: 12px !important;
  }
  .beginText {
    font-size: 13px;
  }
  .sideBg {
    padding: 0 !important;
    padding-right: 4% !important;
  }
  .registerHead {
    font-size: 20px !important;
    padding-bottom: 17px !important;
  }
  .MuiFormControlLabel-label {
    font-size: 14px !important;
  }
  .talenetP {
    font-size: 15px !important;
  }
  .otpContainer {
    padding: 0 !important;
  }
  .filterDrop {
    font-size: 15px !important;
  }
  .flexWrapResp {
    flex-wrap: wrap !important;
  }
  .skillText {
    font-size: 13px !important;
  }
  .flexDirectionRowResp {
    flex-direction: row !important;
  }
  .designBigHead {
    padding-top: 20% !important;
  }
  .employeeHead {
    font-size: 17px !important;
  }
  .employeeHead1 {
    font-size: 24px !important;
  }
  .priceHeadEmployee {
    font-size: 15px !important;
  }

  .budgetHeadEmployee {
    display: block;
    text-align: center !important;
    font-size: 16px !important;
  }

  .respoEmail {
    font-size: 15px !important;
    padding-left: 0 !important;
  }
  .paddingZeroResp {
    padding: 0 !important;
  }
  .MuiTableCell-root {
    font-size: 14px !important;
  }
  .makeStyles-content-6 {
    padding: 120px 16px !important;
  }
  .makeStyles-content-20 {
    padding: 120px 16px !important;
  }
  .borderRadNone {
    border-radius: 0 !important;
  }
  .deleteVec {
    max-width: 200px !important;
    max-height: 200px !important;
  }
  .employeeProjdetails {
    padding: 20px !important;
  }
  .employeeInner {
    justify-content: flex-start !important;
    margin-left: 12px !important;
    margin-right: 0px !important;
  }
  .browseChange {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dollarDigit {
    font-size: 25px !important;
  }
  .monthText {
    font-size: 17px !important;
  }
  .subsText {
    font-size: 15px !important;
  }
  .subsParagraph {
    font-size: 17px !important;
  }
  /* .slick-prev {
  display: block !important;
  left: -14px !important; 
}
.slick-next {
  display: block !important;
  right: -14px !important; 
} */
  .membership-grid1 {
    padding-top: 20px !important;
  }
  .alignBaselineRsp {
    align-items: baseline !important;
  }
  .testinomialPaddingTop {
    padding-top: 15px !important;
  }
  .testimonialContainer {
    height: 335px !important;
  }
}

.MuiTypography-p {
  white-space: pre-line;
}
